import React, { useState, useEffect } from 'react';
import './EndlessRacer.css';
import lamboImage from './images/lambo-racer.png';
import rolexImage from './images/rolex.png';

function EndlessRacer({ onClose }) {
  const [lane, setLane] = useState(1); // Lanes are 0,1,2,3
  const [rolexes, setRolexes] = useState([]);
  const [score, setScore] = useState(0);
  const lanes = [0, 1, 2, 3];

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft' && lane > 0) {
        setLane(lane - 1);
      } else if (e.key === 'ArrowRight' && lane < 3) {
        setLane(lane + 1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Rolex generation
    const rolexInterval = setInterval(() => {
      setRolexes((prevRolexes) => [
        ...prevRolexes,
        {
          lane: Math.floor(Math.random() * 4),
          position: 0,
          id: Date.now(),
        },
      ]);
    }, 1000); // Generate a new Rolex every second

    // Rolex movement
    const gameInterval = setInterval(() => {
      setRolexes((prevRolexes) =>
        prevRolexes
          .map((rolex) => ({
            ...rolex,
            position: rolex.position + 5, // Move Rolex down
          }))
          .filter((rolex) => rolex.position < 500) // Remove Rolexes that have moved off-screen
      );
    }, 50);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      clearInterval(rolexInterval);
      clearInterval(gameInterval);
    };
  }, [lane]);

  useEffect(() => {
    // Check for collisions
    rolexes.forEach((rolex) => {
      if (rolex.lane === lane && rolex.position >= 400 && rolex.position <= 450) {
        // Collision detected
        setScore((prevScore) => prevScore + 1);
        setRolexes((prevRolexes) =>
          prevRolexes.filter((r) => r.id !== rolex.id)
        );
      }
    });
  }, [rolexes, lane]);

  return (
    <div className="endless-racer">
      <button className="close-button" onClick={onClose}>
        Close Game
      </button>
      <div className="score">Score: {score}</div>
      <div className="game-area">
        {/* Lanes */}
        {lanes.map((laneIndex) => (
          <div className="lane" key={laneIndex}>
            {/* Rolexes in this lane */}
            {rolexes
              .filter((rolex) => rolex.lane === laneIndex)
              .map((rolex) => (
                <img
                  key={rolex.id}
                  src={rolexImage}
                  alt="Rolex"
                  className="rolex"
                  style={{ top: rolex.position }}
                />
              ))}
            {/* Lambo */}
            {lane === laneIndex && (
              <img
                src={lamboImage}
                alt="Lambo"
                className="lambo"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default EndlessRacer;
