import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import {
  Button,
  Container,
  Alert,
  Navbar,
  Nav,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import {
  TOKEN_ADDRESS,
  BASE_RPC_URL,
  MINIMUM_BALANCE,
  BASE_COLOR,
} from './config';
import { ERC20_ABI } from './abi';
import './App.css';
import logo from './images/logo.webp';
import lamboImage from './images/lambo.png';
import rolexImage from './images/rolex.png';
// App.js
import EndlessRacer from './EndlessRacer';



function FallingObjects() {
  const numObjects = 20; // Adjust the number of falling objects
  const objects = [];

  for (let i = 0; i < numObjects; i++) {
    const isLambo = Math.random() > 0.5;
    const left = Math.random() * 100; // Random horizontal position
    const delay = Math.random() * -20; // Random animation delay
    const size = Math.random() * 30 + 20; // Random size between 20px and 50px
    const style = {
      left: `${left}%`,
      animationDelay: `${delay}s`,
      width: `${size}px`,
      height: `${size}px`,
    };
    objects.push(
      <div className="falling-object" style={style} key={i}>
        <img
          src={isLambo ? lamboImage : rolexImage}
          alt="Falling Object"
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
    );
  }

  return <div className="falling-objects">{objects}</div>;
}

function App() {
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState(null);
  const [balance, setBalance] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showGame, setShowGame] = useState(false);

const startGame = () => {
  setShowGame(true);
};

const closeGame = () => {
  setShowGame(false);
};


  useEffect(() => {
    if (address && provider) {
      checkBalance();
    }
  }, [address]);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        setLoading(true);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const baseProvider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(baseProvider);
        const signer = baseProvider.getSigner();
        const userAddress = await signer.getAddress();
        setAddress(userAddress);
      } catch (error) {
        console.error('User rejected the request.');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please install MetaMask to use this app.');
    }
  };


  

  const checkBalance = async () => {
    try {
      setLoading(true);
      const baseProvider = new ethers.providers.JsonRpcProvider(BASE_RPC_URL);
      const tokenContract = new ethers.Contract(
        TOKEN_ADDRESS,
        ERC20_ABI,
        baseProvider
      );
      const userBalance = await tokenContract.balanceOf(address);
      const tokenDecimals = await tokenContract.decimals();
      const formattedBalance = ethers.utils.formatUnits(
        userBalance,
        tokenDecimals
      );
      setBalance(formattedBalance);
      setHasAccess(Number(formattedBalance) >= MINIMUM_BALANCE);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  
  const LoadingScreen = () => (
    <div className="loading-screen">
      <img src={logo} alt="Loading..." className="loading-logo" />
    </div>
  );  

  const renderContent = () => {
    if (loading) {
      return <LoadingScreen />;
    }
    if (!address) {
      return (
        <div className="text-center main-content">
          <center><img src={logo} /> </center> 
          <Button
            variant="primary"
            size="lg"
            onClick={connectWallet}
            className="blinking"
          >
            Connect Wallet to Enter the LARP Zone 🚀
          </Button>
        </div>
      );
    } else if (hasAccess) {
      return (
        <Container className="text-center main-content">
          <center><img src={logo} /> </center> 
          <Alert variant="success" className="text-center blinking">
            Welcome to the LARP Dashboard! No Lambo = No Life 🚀
          </Alert>
          {/* Dashboard Content */}
          <h3 className="text-center">🔥 Hot Meme Tokens 🔥</h3>
          <Row>
            <Col md={4}>
              <Card className="mb-4" bg="dark" text="white">
                <Card.Body>
                  <Card.Title className="blinking">🚀 $MOON</Card.Title>
                  <Card.Text>To the moon!</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4" bg="dark" text="white">
                <Card.Body>
                  <Card.Title className="blinking">🐕 $DOGE</Card.Title>
                  <Card.Text>Much Wow!</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4" bg="dark" text="white">
                <Card.Body>
                  <Card.Title className="blinking">🐶 $SHIB</Card.Title>
                  <Card.Text>The Doge Killer!</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* Additional Info */}
          <h3 className="text-center mt-5">💎 Your LARP Balance 💎</h3>
          <p className="text-center blinking">{balance} LARP Tokens</p>
          <h3 className="text-center mt-5">🤑 Latest Market News 🤑</h3>
          <ul>
            <li>Bitcoin breaks $60k barrier!</li>
            <li>Ethereum 2.0 is coming soon!</li>
            <li>Altcoins are on the rise!</li>
          </ul>

          {showGame && <EndlessRacer onClose={closeGame} />}
<div className="text-center mt-4">
      <Button
        variant="primary"
        size="lg"
        onClick={startGame}
        className="game-button"
      >
        Play Endless Racer 🏎️
      </Button>
    </div>
    <br />
    <br />
        </Container>
      );
    } else {
      return (
        <Container className=" text-center main-content">
          <center><img src={logo} /> </center> 
          <Alert variant="danger" className="blinking">
            You are poor! No Access for You! 😭
          </Alert>
          <p>Acquire more LARP tokens to unlock the exclusive dashboard!</p>
        </Container>
      );
    }
  };

  return (
    <div style={{ backgroundColor: BASE_COLOR, minHeight: '100vh' }}>
 {!showGame && <FallingObjects /> }

      {renderContent()}
    </div>
  );
}

export default App;
